export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_COUNT = "GET_COUNT";
export const GET_DEATHSEARCH = "GET_DEATHSEARCH";
export const SET_LOADING = "SET_LOADING";
export const SET_ANALYSIS_LOADING = "SET_ANALYSIS_LOADING";
export const GET_ANALYSIS = "GET_ANALYSIS";
export const GET_HOUSE_DETAIL = "GET_HOUSE_DETAIL";
export const GET_FAMILY_BY_PHONE = "GET_FAMILY_BY_PHONE";
export const GET_MEMBER = "GET_MEMBER";
export const HOUSE_LOADING = "HOUSE_LOADING";
export const ADVANCE_SEARCH = "ADVANCE_SEARCH";
export const ADVANCE_LOAD = "ADVANCE_LOAD";
export const GET_PROGRESS = "GET_PROGRESS";
export const SET_PROGRESS_LOAD = "SET_PROGRESS_LOAD";
export const CLEAR_DATA_ADVANCE = "CLEAR_DATA_ADVANCE";
export const SAVE_HOUSE = "SAVE_HOUSE";
export const SHOW_HOUSE = "SHOW_HOUSE";
export const GET_SURVEY_ERRORS = "GET_SURVEY_ERRORS";
export const SAVE_INSTITUTE = "SAVE_INSTITUTE";
export const SAVE_TOURISM = "SAVE_TOURISM";
export const SAVE_CLUB = "SAVE_CLUB";
export const SAVE_ITEM = "SAVE_ITEM";
export const SAVE_SCHOOL = "SAVE_SCHOOL";
export const SHOW_SCHOOL = "SHOW_SCHOOL";
export const SHOW_INSTITUTE = "SHOW_INSTITUTE";
export const SHOW_TOURISM = "SHOW_TOURISM ";
export const SHOW_CLUB = "SHOW_CLUB";
export const SHOW_DATA = "SHOW_DATA";
export const SAVE_PROJECT = "SAVE_PROJECT";
export const SAVE_PRODEDUCATION = "SAVE_PRODEDUCATION";
export const GET_INSTITUTE_ERROR = "GET_INSTITUTE_ERROR";
export const GET_INSTITUTE_ANALYSIS = "GET_INSTITUTE_ANALYSIS";
export const GET_ANALYSIS_OVERALL = "GET_ANALYSIS_OVERALL";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const GET_SCHOOL_LEVEL = "GET_SCHOOL_LEVEL";
export const GET_SCHOOL_STUDENT = "GET_SCHOOL_STUDENT";
export const START_SNACK = "START_SNACK";
export const CLOSE_SNACK = "CLOSE_SNACK";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const SET_SMS_ANALYSIS_LOADING = "SET_SMS_ANALYSIS_LOADING";
export const GET_SMS_ANALYSIS = "GET_SMS_ANALYSIS";
export const GET_CAMPAIGN_ANALYSIS = "GET_CAMPAIGN_ANALYSIS";
export const ADVANCE_CUSTOM_SEARCH = "ADVANCE_CUSTOM_SEARCH";
